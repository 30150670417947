// Demo Theme Options

import Cookies from 'js-cookie';

$(document).ready(() => {

    $('.btn-open-options').click(function () {
        $('.ui-theme-settings').toggleClass('settings-open');
    });

    $('.close-sidebar-btn').click(function () {

        var classToSwitch = $(this).attr('data-class');
        var containerElement = '.app-container';
        $(containerElement).toggleClass(classToSwitch);

        var closeBtn = $(this);

        if (closeBtn.hasClass('is-active')) {
            closeBtn.removeClass('is-active');
            Cookies.set('is-sidebar-closed', 'no');

        } else {
            closeBtn.addClass('is-active');
            Cookies.set('is-sidebar-closed', 'yes');
        }

        var finalContainerClass = $(containerElement).attr('class');

        Cookies.set('container-class', finalContainerClass);
    });

    var isClosedSidebar = Cookies.get('is-sidebar-closed');

    if (isClosedSidebar == 'yes') {
        $('.close-sidebar-btn').addClass('is-active');
        $('.app-container').addClass('closed-sidebar');
    } else {
        $('.close-sidebar-btn').removeClass('is-active');
        $('.app-container').removeClass('closed-sidebar');
    }


    $(window).on('resize', function(){
        var win = $(this);
        if (win.width() < 1250) {
            $('.app-container').addClass('closed-sidebar-mobile closed-sidebar');
        }
        else
        {
            $('.app-container').removeClass('closed-sidebar-mobile closed-sidebar');
        }

    });


    $('.switch-container-class').on('click', function () {

        var classToSwitch = $(this).attr('data-class');
        var containerElement = '.app-container';
        $(containerElement).toggleClass(classToSwitch);

        $(this).parent().find('.switch-container-class').removeClass('active');
        $(this).addClass('active');

        var finalContainerClass = $(containerElement).attr('class');

        Cookies.set('container-class', finalContainerClass);
    });

    $('.switch-theme-class').on('click', function () {

        var classToSwitch = $(this).attr('data-class');
        var containerElement = '.app-container';

        if (classToSwitch == 'app-theme-white') {
            $(containerElement).removeClass('app-theme-gray');
            $(containerElement).addClass(classToSwitch);
        }

        if (classToSwitch == 'app-theme-gray') {
            $(containerElement).removeClass('app-theme-white');
            $(containerElement).addClass(classToSwitch);
        }

        if (classToSwitch == 'body-tabs-line') {
            $(containerElement).removeClass('body-tabs-shadow');
            $(containerElement).addClass(classToSwitch);
        }

        if (classToSwitch == 'body-tabs-shadow') {
            $(containerElement).removeClass('body-tabs-line');
            $(containerElement).addClass(classToSwitch);
        }

        $(this).parent().find('.switch-theme-class').removeClass('active');
        $(this).addClass('active');

        var finalContainerClass = $(containerElement).attr('class');

        Cookies.set('container-class', finalContainerClass);

    });

    $('.switch-header-cs-class').on('click', function () {
        var classToSwitch = $(this).attr('data-class');
        var containerElement = '.app-header';

        $('.switch-header-cs-class').removeClass('active');
        $(this).addClass('active');

        $(containerElement).attr('class', 'app-header');
        $(containerElement).addClass('header-shadow ' + classToSwitch);

        var finalHeaderClass = $(containerElement).attr('class');

        Cookies.set('header-class', finalHeaderClass);
    });

    $('.switch-sidebar-cs-class').on('click', function () {
        var classToSwitch = $(this).attr('data-class');
        var containerElement = '.app-sidebar';

        $('.switch-sidebar-cs-class').removeClass('active');
        $(this).addClass('active');

        $(containerElement).attr('class', 'app-sidebar');
        $(containerElement).addClass('sidebar-shadow ' + classToSwitch);

        var finalSidebarClass = $(containerElement).attr('class');

        Cookies.set('sidebar-class', finalSidebarClass);
    });

    var containerCookieClass = Cookies.get('container-class');
    var headerCookieClass = Cookies.get('header-class');
    var sidebarCookieClass = Cookies.get('sidebar-class');

// Default Layout Options

    var containerDefaultClasses = 'app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar';
    var headerDefaultClasses = 'app-header header-shadow';
    var sidebarDefaultClasses = 'app-sidebar sidebar-shadow';

    if (!containerCookieClass) {
        $('.app-container').attr('class', containerDefaultClasses);
    } else {
        $('.app-container').attr('class', containerCookieClass);
    }

    if (!headerCookieClass) {
        $('.app-header').attr('class', headerDefaultClasses);
    } else {
        $('.app-header').attr('class', headerCookieClass);
    }

    if (!sidebarCookieClass) {
        $('.app-sidebar').attr('class', sidebarDefaultClasses);
    } else {
        $('.app-sidebar').attr('class', sidebarCookieClass);
    }

});